
@import '../../../styles/base';

.banner {
  background: $blue-dark;
  background: linear-gradient(180deg,#1c3385,#3555a3);
  color: $white;
  padding: 25px 30px;
  margin-bottom: 3rem;
}

.header {
  display: flex;
  align-items: center;
}

  .logo {
    flex: 0 0 120px;
    margin-right: 2rem;

    @include untilTablet {
      display: none;
    }
  }

  .text {
    margin-right: 1rem;
    h3 {
      margin: 0 0 1rem;
    }
    p {
      font-size: $font-m;
      margin: 0;
    }
  }

  .toggle {
    height: 14px;
    width: 14px;
    margin-left: auto;
    border: 3px solid $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    flex: 0 0 14px;

    &.open {
      transform: rotate(-135deg);
    }
  }

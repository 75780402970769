// media query mixins
$mobile: 375px;
$untiltablet: 767px;
$tablet: 768px;
$untildesktop: 1023px;
$desktop: 1024px;

@mixin fromMobile {
  @media only screen and (min-width: $mobile) {
    @content;
  }
}

@mixin untilTablet {
  @media only screen and (max-width: $untiltablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $untildesktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

// colors
$grey: #ddd;
$grey-light: #f9f9f9;
$grey-dark: #bbbbbd;
$grey-text: #505050;
$green: #63ad31;
$green-dark: #128d15;
$green-light: #f6faf3;
$blue: #0368d9;
$blue-dark: #162a75;
$blue-darker: #070749;
$white: #fff;
$black: #000;
$orange: #f9d069;
$orange-dark: #e69038;
$pale-blue: #f8fcfe;

$search-panel: $white;

// sizes
$max-width: 1200px;
$font-xs: 1rem;
$font-s: 1.1rem;
$font-sm: 1.2rem;
$font-sm-default: 1.3rem;
$font-default: 1.4rem;
$font-m: 1.6rem;
$font-ml: 1.8rem;
$font-l: 2rem;
$font-lxl: 2.2rem;
$font-xl: 2.4rem;
$font-xxl: 3.6rem;
$font-bigPrice: 3rem;
$font-midPrice: 2.8rem;
$font-smallPrice: 2.2rem;

$border-radius: 12px;

//fonts
$font-boldest: 900;
$font-bold: 700;
$font-normal: 400;
$font: "Avenir Next", Arial, helvetica, sans-serif;

@mixin gradient {
  background-image: linear-gradient(
    rgb(0, 94, 199) 0%,
    rgb(0, 94, 199) 25%,
    rgb(5, 116, 240)
  );
}

// misc
@mixin container {
  padding: 0 1rem;
  margin: 0 auto;
  clear: both;
  position: relative;
  max-width: $max-width;
}

@mixin visuallyHidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.visuallyHidden {
  @include visuallyHidden;
}

@mixin hideOnMobile {
  @include untilDesktop {
    @include visuallyHidden;
  }
}

/* Clearfix
  Usage: @include cf;
*/
@mixin cf() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin boxShadow() {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

// headings

@mixin headingH1 {
  font-family: $font !important;
  font-weight: $font-boldest !important;
  color: $blue-dark !important;
  line-height: 1.2;
  margin: 0;
  font-size: $font-midPrice; //2.8rem

  @include fromTablet {
    font-size: $font-xxl; //3.6rem
  }

  strong {
    font-weight: $font-boldest !important;
    display: block;
    margin-bottom: 0.5rem;
  }
}

@mixin headingH2 {
  font-weight: bold;
  font-size: $font-ml; //1.8rem
  @include fromTablet {
    font-size: $font-l; //2rem
  }
}

@mixin headingH3 {
  font-weight: bold;
  font-size: $font-l; //2rem
  @include fromTablet {
    font-size: $font-lxl; //2.2rem
  }
}

@mixin pText {
  font-size: $font-default;
}

@mixin descriptionP {
  font-size: $font-default;
  padding: 0;
  margin: 0 0 1rem 0;
  font-size: $font-m;
}

@mixin detailsLink {
  font-size: $font-m;
  padding: 0;
  margin: 0;
}

@mixin descriptionH3 {
  @include headingH3;
  margin: 0 0 1rem 0;
  padding: 0;
}

@mixin intro {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
  margin: 0;
  font-family: $font !important;
  font-weight: $font-normal !important;
  font-size: $font-ml;

  p {
    padding: 0;
    margin: 0;
    font-family: $font !important;
    font-weight: $font-normal !important;

    & * {
      font-family: $font !important;
      font-weight: $font-normal !important;
    }
  }

  @include fromTablet {
    font-size: $font-l;
    gap: 2rem;
  }
}

@mixin ticketShape {
  @include boxShadow;
  border-radius: 0 $border-radius $border-radius $border-radius;
  padding: 0;
  margin: 0 0 2rem 0;
  background-color: $white;

  @include fromDesktop {
    margin: 0 0 3rem 0;
  }

  &.hasPersuasion {
    margin-top: 3rem;
    position: relative;
  }

  h3 {
    @include descriptionH3;

    strong {
      font-weight: inherit;
    }
  }
}

@mixin cartListItem {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 2rem 1rem 2rem 0;
  border-top: 1px solid $grey;
  &:last-child {
    border-bottom: 1px solid $grey;
  }
}

@mixin cartListInfo {
}

@mixin ticketProduct {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin ticketProductImage {
  flex-basis: 100%;
  margin: 0;
  border-radius: 0 $border-radius 0 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: 3 / 2;
  background-color: $grey-light;

  @include fromTablet {
    border-radius: 0;
    flex-basis: 33%;
    margin: 0;
    aspect-ratio: unset;
  }

  img {
  }

  .swiper {
    aspect-ratio: 3 / 2;

    @include fromTablet {
      aspect-ratio: unset;
    }
  }
}

@mixin ticketProductDetails {
  flex-basis: 100%;
  padding: 0 1rem 2rem 1rem;

  @include fromTablet {
    flex-basis: 50%;
    flex-grow: 1;
    padding: 2rem 2rem 2rem 0;
  }
}

@mixin ticketProductFullWidth {
  background-color: $white;
  border-radius: 0 $border-radius 0 0;
  padding: 2rem 1rem 1rem 1rem;

  @include fromTablet {
    padding: 2rem 2rem 1rem 2rem;
  }
}

@mixin price {
  margin: 0;
  padding: 0;
  color: $black;
  line-height: 1.4;

  span.from {
    font-size: $font-default;
    display: block;
  }
  strong.fromPrice {
    line-height: 1.2;
    display: block;
    font-size: $font-midPrice;
  }
  span.total {
    font-size: $font-default;
    display: block;
  }
  span.totalStay {
    // Rooms page
    font-size: $font-default;
    display: block;
  }
  em.textBelow {
    font-style: normal;
    display: block;
    font-size: $font-sm;
  }
}

@mixin ticketFooter($type: default) {
  border-top: solid #ddd 1px;
  border-radius: 0 0 $border-radius $border-radius;
  margin: 0;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background-color: $grey-light;
  flex-direction: column;
  border-top: none;
  padding: 1rem;

  @include fromTablet {
    padding: 2rem 2rem 1rem 2rem;
  }

  &.hotelFooter {
    background-color: $grey-light;
    padding: 1rem;

    @include fromTablet {
      padding: 2rem 0 1rem 0;
    }
  }

  &.roomsFooter {
    background-color: $grey-light;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;

    @include fromTablet {
      align-items: center;
      padding: 2rem 0 1rem 0;
    }
  }

  .footerPricingCta {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 2rem;

    @include fromTablet {
      width: auto;
      padding-left: calc(33% + 2rem);
      flex-direction: row;
      gap: 2rem;
    }

    &.roomsCta {
      flex-shrink: 0;
      flex-direction: row;
      gap: 2rem;
      align-items: flex-start;
      text-align: left;
      @include fromTablet {
        width: 100%;
        padding-left: calc(33% + 2rem);
      }
    }
  }

  .callToAction {
    @include fromTablet {
      padding-right: 2rem;
    }
  }

  .productPricing {
    flex-grow: 1;
  }
}

// loading spinner
@mixin loading() {
  position: relative;
  pointer-events: none;

  // overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 3;
    opacity: 0.7;
  }

  // spinner
  &::after {
    background: none;
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    margin-left: -10px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    border: 4px solid $green;
    border-top-color: transparent;
    z-index: 4;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@mixin btn {
  font-size: $font-m;
  padding: 1.2rem 2rem;
  color: $white;
  background: $blue;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;

  &:hover,
  &:focus {
    background: $blue-dark;
    transform: scale(1.05);
  }

  &:disabled {
    background: $grey;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background: $grey;
    }
  }

  &.selected {
    background: $green;
    color: $white;

    &:hover,
    &:focus {
      background: $green-dark;
    }
  }

  &.small {
    font-size: $font-s;
    padding: 1rem 1.8rem;
  }
}

@mixin btnAlt {
  @include btn;

  outline: 1px solid $blue;
  color: $blue;
  background: none;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: $blue-dark;
    background: none;
  }
}

@mixin btnAltBack {
  @include btn;

  outline: none;
  color: $blue;
  background: none;
  margin-right: 10px;
  position: relative;

  &:hover,
  &:focus {
    color: $blue-dark;
    background: none;
  }

  &::before {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border: 1px solid $blue;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
    position: absolute;
    left: 7px;
    top: 17px;
  }
}

@mixin unorderedList($listItemMargin: 0) {
  margin: 0 0 1rem 0;
  padding: 0;

  li {
    list-style-type: none;
    position: relative;
    padding: 0 0 0 3rem;
    margin: 0;
    margin-bottom: $listItemMargin;
    list-style-image: none;

    &::before {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 10px;
      background: $listdot;
    }
  }
}

@mixin info {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  vertical-align: middle;
  width: 20px;
  text-transform: none;
  height: 16px;

  &::after {
    content: "i";
    display: inline-block;
    color: $white;
    background: $blue;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    line-height: 1.2;
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
    top: 0;
    position: absolute;
    right: 0;

    &:hover {
      .infoText {
        display: block;
      }
    }
  }
}

@mixin toggleButton {
  background: 0;
  border: 0;
  display: inline-block;
  padding: 0 15px 0 7px;
  position: relative;
  cursor: pointer;
  transition: none;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-border-radius: none;
  border-radius: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: $blue;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    border: 2px solid $blue;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    right: 0;
    top: calc(50% - 4px);
  }

  &[aria-expanded="true"] {
    &::after {
      transform: rotate(-135deg);
      top: calc(50%);
    }
  }
}

@mixin select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid $grey;
  background-color: #fff;
  border-radius: 10px;
  background-image: url("../images/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 1em auto;
  background-position: right 10px center;
  line-height: 1.5;
  padding: 0 30px 5px 15px;
  font-size: 1.8rem;

  &.filterBy,
  &.sortBy {
    background-position: right 10px center, 10px center;
    padding-left: 40px;

    @include fromDesktop {
      font-size: $font-l;
      padding: 3px 35px 5px 40px;
    }
  }

  &.filterBy {
    background-image: url("../images/icons/dropdown.svg"),
      url("../images/icons/filter.svg");
  }

  &.sortBy {
    background-image: url("../images/icons/dropdown.svg"),
      url("../images/icons/sort.svg");
  }
}

@mixin stars {
  display: block;
  background: url(../images/icons/star.svg);
  background-size: 20px auto;
  min-height: 20px;
}

.stars {
  @include stars;
}

@mixin tag {
  display: inline-block;
  background: $blue-dark;
  color: #fff;
  padding: 2px 10px;
  box-shadow: 0 0 2px 0 rgba(255, 255, 255, 0.5);
}

@mixin modalLink {
  align-self: center;
  text-decoration: none;
  font-size: $font-m;
  flex-shrink: 0;
}

@mixin buttonHolder {
  display: flex;
  gap: 1rem;
  text-align: right;
  align-items: center;

  p {
    margin: 0;
  }
}

@mixin qtyChangerContainer {
  padding-top: 2px;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0.5rem;
}

@mixin qtyChanger {
  border: 2px solid $grey;
  font-size: 1rem;
  cursor: pointer;
  color: $blue;
  border-radius: 50%;
  background: none;
  position: relative;
  padding: 0;
  background-color: $white;
  height: 50px;
  width: 50px;

  @include fromDesktop {
    height: 40px;
    width: 40px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: transparent;
    border-radius: 2px;
  }

  &::before {
    height: 4px;
    width: 20px;
    left: calc((46px - 20px) / 2);
    top: calc((46px / 2) - 2px);

    @include fromDesktop {
      width: 16px;
      left: calc((36px - 16px) / 2);
      top: calc((36px / 2) - 2px);
    }
  }

  &::after {
    width: 4px;
    height: 20px;
    top: calc((46px - 20px) / 2);
    left: calc((46px / 2) - 2px);

    @include fromDesktop {
      height: 16px;
      top: calc((36px - 16px) / 2);
      left: calc((36px / 2) - 2px);
    }
  }

  &.qtyChangerRemove {
    &::before {
      background-color: $blue;
    }
    &.qtyChangerDisabled {
      cursor: not-allowed;

      &::before {
        background-color: $grey;
      }
    }
  }

  &.qtyChangerAdd {
    &::before,
    &::after {
      background-color: $blue;
    }
    &.qtyChangerDisabled {
      cursor: not-allowed;

      &::before,
      &::after {
        background-color: $grey;
      }
    }
  }
}

@mixin qtyChangerNumber {
  text-align: center;
  color: $blue;
  font-size: $font-l;
  font-weight: 900;
  line-height: 1.1;
  display: inline-block;
  padding: 0;
  min-width: 30px;
}

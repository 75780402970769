@import "../../../styles/base";

.container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
  @include fromTablet {
    gap: 2rem;
  }

  // :global to allow non css module class from markup
  :global(.popup-content) & {
    justify-content: flex-end;
  }

  p {
    margin: 0;
    text-align: right;
  }
}

.priceAndLabel {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  justify-content: flex-end;
  align-items: flex-start;
  @include fromTablet {
    flex-wrap: nowrap;
  }
}

p.title {
  font-size: $font-default;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-end;
  column-gap: 0.5rem;
  @include fromTablet {
    flex-direction: column;
    text-align: right;
    align-items: stretch;
  }

  strong {
    font-weight: normal;
    line-height: 1.4;
    font-size: $font-default;
    display: inline-block;
    @include fromMobile {
      font-size: $font-m;
      display: block;
    }
  }
}

p.price {
  text-align: right;
  min-width: 70px;
  font-size: $font-sm-default;
  @include fromMobile {
    min-width: 90px;
  }
  strong {
    display: block;
    line-height: 1.2;
    font-size: $font-smallPrice;
    @include fromMobile {
      font-size: $font-midPrice;
    }
  }
}

em.sub {
  display: inline-block;
  font-style: normal;
  font-size: $font-sm-default;
}

.qtyChangerContainer {
  @include qtyChangerContainer;
}

.qtyChanger {
  @include qtyChanger;
}

.qty {
  @include qtyChangerNumber;
}

.loading {
  color: #bbb;
  cursor: default;
  opacity: 0.5;
}

@import '../../../../styles/base';

.modal {
  max-height: 100vh;
  border-radius: 0;
  padding: 2rem 2rem 10rem;
  overflow-y: auto;

  @include fromTablet {
    max-height: calc(80vh - 80px);
    border-radius: $border-radius $border-radius 0 0;
    padding: 2rem 2rem 10rem;
    overflow: hidden;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid $white;
  height: 32px;
  width: 32px;
  background: $blue;
  cursor: pointer;
  z-index: 1;

  @include fromTablet {
    bottom: calc(100% + 20px);
    border: 1px solid $white;
    background: none;
    top: -50px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 26px;
    background: $white;
    border-radius: 3px;
    position: absolute;
    top: calc(50% - 2px);
    left: 1px;
    transform: rotate(45deg);
  }

  &::after {
    height: 26px;
    width: 3px;
    top: 1px;
    left: calc(50% - 2px);
  }
}

.popupHeader {
  h3 {
    margin-top: 0;
    font-size: $font-xl;
  }
}

.popupBody {
  display: flex;
  flex-direction: column;

  @include fromTablet {
    flex-direction: row;

    > div {
      flex: 0 0 50%;
      max-height: calc(80vh - 80px - 83px);
      overflow-y: auto;

      &:first-child {
        padding-right: 20px;
        margin-right: auto;
      }
    }
  }

  div:first-child {
    // show ticket qty first on mobile
    order: 1;
    @include fromTablet {
      order: 0;
    }
  }
}

.popupFooter {
  padding: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $grey-light;
  text-align: center;

  @include fromTablet {
    position: absolute;
    border-radius: 0 0 $border-radius $border-radius;
    bottom: auto;
    top: 100%;
    text-align: right;
  }
}

.btnAlt {
  @include btnAlt;
}

.toggle {
  text-decoration: none;
  position: relative;
  padding-right: 18px;
  display: inline-block;

  &::after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border: 2px solid $blue;
    border-width: 2px 2px 0 0;
    position: absolute;
    transform: rotate(135deg);
    right: 0%;
    top: 4px;
  }
}

.open {
  &::after {
    transform: rotate(-45deg);
    top: 8px;
  }
}

